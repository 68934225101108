import { Link } from "react-router-dom";

const Notifications = () => {
  return (
    <div>
      <h1>Notifications</h1>
      <Link to="/dashboard">Back</Link>
    </div>
  )
}

export default Notifications;