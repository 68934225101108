import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div>
      <h1>About Us</h1>
      <Link to="/">Back</Link>
    </div>
  )
}

export default AboutUs;